<template>
    <div class="vue-box">
        <div class="c-panel">
            <!-- 参数栏 -->
            <el-form :inline="true" size="mini">
                <el-form-item label="物料名称：">
                    <el-input v-model="p.name" placeholder="模糊查询"></el-input>
                </el-form-item>
                <el-form-item label="物料编号：">
                    <el-input v-model="p.code" placeholder="模糊查询"></el-input>
                </el-form-item>

                <el-form-item label="分类：">
                   <select-category v-model="p.categoryId"></select-category>
                </el-form-item>

                <el-form-item style="min-width: 0px">
                    <el-button type="primary" icon="el-icon-search" @click="f5()">查询
                    </el-button>
                    <el-button type="primary" icon="el-icon-plus" @click="add">增加</el-button>
                     <el-button type="success" icon="el-icon-plus" @click="category">分类管理</el-button>
                </el-form-item>
            </el-form>
            <!-- <div class="c-title">数据列表</div> -->
            <el-table :data="dataList" size="mini">
                <el-table-column label="封面">
                    <template slot-scope="s">
                        <el-image style="width:50px; height:50px"
                        :src="s.row.imageFile[0]? s.row.imageFile[0].url: require('@/static/img/no.png') "
                         :preview-src-list="[s.row.imageFile[0]? s.row.imageFile[0].url:require('@/static/img/no.png')]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="物料名称" prop="name"></el-table-column>
                <el-table-column label="分类" prop="categoryId">
                    <template slot-scope="s">
                        <template  v-for="item in categorys">
                        <span v-if="item.id == s.row.categoryId" :key="item.id">{{item.name}}</span>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="物料编号" prop="code"></el-table-column>
                <el-table-column label="规格" prop="sku"></el-table-column>
                <el-table-column label="价格" prop="price"></el-table-column>
                <el-table-column label="单位" prop="unit"></el-table-column>
                <el-table-column label="数量" prop="num"></el-table-column>
                <el-table-column label="损耗量" prop="lossNum"></el-table-column>
                <el-table-column label="状态" prop="stateEnum"></el-table-column>
                <el-table-column label="当前位置" prop="locationName"></el-table-column>
                <el-table-column label="更新时间" min-width="100" :show-overflow-tooltip="true" prop="updateTime"></el-table-column>
                 <el-table-column prop="address" label="操作" width="220px">
                    <template slot-scope="s">
						<el-dropdown size="small" split-button type="primary" @command="handleCommand">
							操作
						  <el-dropdown-menu slot="dropdown">
						    <el-dropdown-item icon="el-icon-s-tools" :command="'partner-'+s.row.id" >配件</el-dropdown-item>
						    <el-dropdown-item icon="el-icon-view" :command="'show-'+s.row.id">查看</el-dropdown-item>
						    <el-dropdown-item icon="el-icon-edit" :command="'update-'+s.row.id">修改</el-dropdown-item>
						    <el-dropdown-item icon="el-icon-delete" :command="'del-'+s.row.id ">删除</el-dropdown-item>
						  </el-dropdown-menu>
						</el-dropdown>
					
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="page-box">
                <el-pagination background layout="total, prev, pager, next, sizes, jumper" :current-page.sync="p.page"
                    :page-size.sync="p.pageSize" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]"
                    @current-change="f5(true)" @size-change="f5(true)">
                </el-pagination>
            </div>
        </div>

        <!-- 增改组件 -->
        <add-or-update ref="add-or-update"></add-or-update>
    </div>
</template>

<script>
import SelectCategory from '../../sa-resources/com-view/select-category.vue';
import addOrUpdate from "./add.vue";
export default {
    components: {
        addOrUpdate,
        SelectCategory,
    },
    data() {
        return {
            p: { pageSize: 10, page: 1, name: "", code: "",categoryId:'' },
            dataCount: 0,
            dataList: [],
            categorys:[]
        };
    },
    methods: {
        // 数据刷新
        f5: function () {
            this.sa.put("/material/listPage", this.p).then((res) => {
                this.dataList = res.data.content.map(item=>{
                    item.imageFile = JSON.parse(item.image);
                    return item;
                });
                console.log(55555555555, this.dataList);
                this.dataCount = res.data.totalElements;
            });
            this.sa.get("/category/list").then(res=>{
                console.log('===========', res.data);
                this.categorys = res.data;
            })
        },
        // 删除
        del: function (id) {
			let rows = this.dataList.filter(v=>v.id==id);
			let data = rows[0];
            this.sa.confirm(
                "是否删除，此操作不可撤销",
                function () {
                    this.sa
                        .delete("/material/delete/" + data.id)
                        .then((res) => {
                            console.log(res);
                            this.sa.arrayDelete(this.dataList, data);
                            this.sa.ok(res.message);
                        });
                }.bind(this)
            );
        },
		
		handleCommand(cmd){
			console.log("打印出信息了", cmd);
			let cmdArr = cmd.split("-");
			let id = parseInt(cmdArr[1]);
			switch(cmdArr[0]){
				case 'update':
					this.update(id);
					break;
				case 'show':
					this.show(id);
					break;
				case 'del':
					this.del(id);
					break;
				case 'partner':
					this.partner(id);
			}
			
		},
		
		partner(id){
			this.sa_admin.navigateTo("materialPartner-list",{id: id});
		},
		
        //更新
        update(id) {
			let rows = this.dataList.filter(v=>v.id==id);
            this.$refs["add-or-update"].open(rows[0]);
        },
		
		
			
        show(id){
			console.log("查询id", id);
            this.sa_admin.navigateTo("material-detail",{id: id})
        },
        //添加
        add: function () {
            this.$refs["add-or-update"].open(0);
        },
        category: function(){
            this.sa_admin.navigateTo("category-list");
        }
    },
    created: function () {
        this.f5();
    },
};
</script>

<style>
</style>
